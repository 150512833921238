



















































































import Vue from "vue";
import cubejs from "@cubejs-client/core";
import jwt from "jsonwebtoken";
import { QueryRenderer } from "@cubejs-client/vue";
import { Utils } from "@/utils";
import * as q from "@/api/cubejs";

export default Vue.extend({
  name: "CustomerOrderHistory",

  components: { QueryRenderer },

  data: function () {
    const cubejsApiToken = jwt.sign(
      { supplierId: this.$store.state.shops.activeShop.id },
      process.env.VUE_APP_CUBEJS_API_SECRET,
      {
        expiresIn: "30d",
      }
    );
    //  Our Cube.js Key and API URL
    const cubejsApi = cubejs(cubejsApiToken, {
      apiUrl: `${process.env.VUE_APP_CUBEJS_API_URL}/cubejs-api/v1`,
    });

    return {
      cubejsApi,
      // Defining Cube.js querys
      queryCustomersHistory: q.supplierCustomerHistory,
    };
  },

  methods: {
    formatCurrency(item: string) {
      return Utils.formatText(item, Utils.TextType.CURRENCY);
    },
    formatDate(item: string) {
      return Utils.formatText(item, Utils.TextType.DATE_TIME);
    },
    customerStats(connectionId: string) {
      this.$router.push({
        name: "my-customer-overview",
        params: { connectionId: connectionId, setActiveTab: "2" },
      });
    },
    orderFor(connectionId: number) {
      this.$router.push({
        name: "customer-order",
        params: {
          connectionId: connectionId.toString(),
        },
      });
    },
  },
});
