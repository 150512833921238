export const supplierOrderStates = {
  measures: ["Orders.count", "Orders.lineItemTotal"],
  dimensions: ["Orders.status"],
  timeDimensions: [
    {
      dimension: "Orders.createdDateTime",
    },
  ],
  order: { "Orders.status": "asc" },
  timezone: "Africa/Johannesburg",
};

export const supplierTotalOrdersPaid = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "This year",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const supplierUnpaidOrders = {
  measures: ["Orders.lineItemTotal"],
  segments: ["Orders.unpaid"],
  timeDimensions: [
    {
      dimension: "Orders.createdDateTime",
      dateRange: "This month",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const supplierPaidOrders = {
  measures: ["Orders.lineItemTotal"],
  segments: ["Orders.paid"],
  timeDimensions: [
    {
      dimension: "Orders.paymentDateTime",
      dateRange: "This month",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const supplierTotalPaidOrdersGraph = {
  measures: ["Orders.lineItemTotal"],
  segments: ["Orders.paid"],
  timeDimensions: [
    {
      dimension: "Orders.paymentDateTime",
      dateRange: "from 11 months ago to now",
      granularity: "month",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const supplierTopPayingCustomers = {
  measures: ["Orders.lineItemTotal"],
  dimensions: ["Customers.name"],
  segments: ["Orders.paid"],
  timeDimensions: [
    {
      dimension: "Orders.paymentDateTime",
      dateRange: "This month",
    },
  ],
  order: {
    "Orders.lineItemTotal": "desc",
  },
  limit: 20,
  timezone: "Africa/Johannesburg",
};

export const totalPaymentsMadeThisMonth = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "This month",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const totalPaymentsMadeToday = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "Today",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const totalPaymentsReceivedThisMonth = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "This month",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const totalPaymentsReceivedToday = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "Today",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const supplierTopPayingCustomersThisMonthTable = {
  measures: ["Payments.totalPaymentAmount"],
  dimensions: ["Customers.id", "Customers.code", "Customers.name"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "This month",
    },
  ],
  order: {
    "Payments.totalPaymentAmount": "desc",
  },
  limit: 20,
  timezone: "Africa/Johannesburg",
};

export const totalPaymentsReceivedThisYearByMonth = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "from 11 months ago to now",
      granularity: "month",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const totalPaymentsReceivedThisWeekByDay = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "This week",
      granularity: "day",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const totalPaymentsMadeThisWeekByDay = {
  measures: ["Payments.totalPaymentAmount"],
  timeDimensions: [
    {
      dimension: "Payments.date",
      dateRange: "This week",
      granularity: "day",
    },
  ],
  timezone: "Africa/Johannesburg",
};

export const supplierCustomerHistory = {
  dimensions: [
    "Connections.id",
    "Customers.id",
    "Customers.code",
    "Customers.name",
    "Customers.contactName",
    "Customers.contactNumber",
    "DistinctOrderingCustomers.orderPaymentDateTime",
    "DistinctOrderingCustomers.orderId",
    "DistinctOrderingCustomers.total",
  ],
  segments: ["Connections.connectedCustomer"],
  order: {
    "DistinctOrderingCustomers.orderPaymentDateTime": "desc",
  },
};

export const supplierConnectedCustomerCount = {
  measures: ["Connections.count"],
  segments: ["Connections.connectedCustomer"],
};

export const supplierPendingConnectionCustomerCount = {
  measures: ["Connections.count"],
  segments: ["Connections.pendingCustomer"],
};

// -----------------------------------------------------------------------------
// Tap2Pay Card Transactions Dashboard
// -----------------------------------------------------------------------------
export const tap2PayCardTransactionsTotalPaymentsInToday = {
  measures: ["InTransactionsPostgreSQL.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsPostgreSQL.dateTime",
      dateRange: "Today",
    },
  ],
  segments: ["InTransactionsPostgreSQL.card"],
};
export const tap2PayCardTransactionsTotalPaymentsInYesterday = {
  measures: ["InTransactionsPostgreSQL.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsPostgreSQL.dateTime",
      dateRange: "Yesterday",
    },
  ],
  segments: ["InTransactionsPostgreSQL.card"],
};
export const tap2PayCardTransactionsTotalPaymentsInAlltime = {
  measures: ["InTransactionsAthena.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsAthena.dateTime",
    },
  ],
  segments: ["InTransactionsAthena.card"],
};
export const tap2PayCardTransactionsTotalDropsInLatestList = {
  order: { "InTransactionsPostgreSQL.dateTime": "desc" },
  segments: ["InTransactionsPostgreSQL.card"],
  dimensions: [
    "InTransactionsPostgreSQL.dateTime",
    "InTransactionsPostgreSQL.amount",
  ],
  filters: [],
  limit: 10,
};
export const tap2PayCardTransactionsTotalDropsInThisWeekByDay = {
  measures: ["InTransactionsPostgreSQL.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsPostgreSQL.dateTime",
      dateRange: "This week",
      granularity: "day",
    },
  ],
  segments: ["InTransactionsPostgreSQL.card"],
};
export const tap2PayCardTransactionsTotalDropsInThisMonth = {
  measures: ["InTransactionsAthena.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsAthena.dateTime",
      dateRange: "This month",
    },
  ],
  segments: ["InTransactionsAthena.card"],
};
export const tap2PayCardTransactionsTotalDropsInThisYearByMonth = {
  measures: ["InTransactionsAthena.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsAthena.dateTime",
      dateRange: "from 11 months ago to now",
      granularity: "month",
    },
  ],
  segments: ["InTransactionsAthena.card"],
};

// -----------------------------------------------------------------------------
// Safe Drop Transactions Dashboard
// -----------------------------------------------------------------------------
export const safeDropTransactionsTotalDropsInToday = {
  measures: ["InTransactionsPostgreSQL.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsPostgreSQL.dateTime",
      dateRange: "Today",
    },
  ],
  segments: ["InTransactionsPostgreSQL.safe"],
};
export const safeDropTransactionsTotalDropsInYesterday = {
  measures: ["InTransactionsPostgreSQL.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsPostgreSQL.dateTime",
      dateRange: "Yesterday",
    },
  ],
  segments: ["InTransactionsPostgreSQL.safe"],
};
export const safeDropTransactionsTotalDropsInLatestList = {
  order: {
    "InTransactionsPostgreSQL.dateTime": "desc",
  },
  segments: ["InTransactionsPostgreSQL.safe"],
  dimensions: [
    "InTransactionsPostgreSQL.dateTime",
    "InTransactionsPostgreSQL.amount",
  ],
  filters: [],
  limit: 10,
};
export const safeDropTransactionsTotalDropsInThisWeekByDay = {
  measures: ["InTransactionsPostgreSQL.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsPostgreSQL.dateTime",
      dateRange: "This week",
      granularity: "day",
    },
  ],
  segments: ["InTransactionsPostgreSQL.safe"],
};
export const safeDropTransactionsTotalDropsInThisMonth = {
  measures: ["InTransactionsAthena.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsAthena.dateTime",
      dateRange: "This month",
    },
  ],
  segments: ["InTransactionsAthena.safe"],
};
export const safeDropTransactionsTotalDropsInThisYearByMonth = {
  measures: ["InTransactionsAthena.totalAmount"],
  timeDimensions: [
    {
      dimension: "InTransactionsAthena.dateTime",
      dateRange: "from 11 months ago to now",
      granularity: "month",
    },
  ],
  segments: ["InTransactionsAthena.safe"],
};
