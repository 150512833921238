var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('S2SForm',{attrs:{"title":"My Customers Order History"}},[_c('v-card',{staticClass:"pa-5"},[_c('QueryRenderer',{attrs:{"cubejs-api":_vm.cubejsApi,"query":_vm.queryCustomersHistory},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var resultSet = ref.resultSet;
return [_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Organisation Code")]),_c('th',{staticClass:"text-left"},[_vm._v("Organisation Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Total")]),_c('th',{staticClass:"text-left"},[_vm._v("Last Order Date")]),_c('th',{staticClass:"text-left"},[_vm._v("Contact Number")]),_c('th',{staticClass:"text-left"},[_vm._v("Shop For")]),_c('th',{staticClass:"text-left"},[_vm._v("Customer Stats")])])]),_c('tbody',_vm._l((resultSet.loadResponse.results[0]
                  .data),function(customer,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(customer["Customers.code"]))]),_c('td',[_vm._v(_vm._s(customer["Customers.name"]))]),_c('td',[_vm._v(" "+_vm._s(_vm.formatCurrency( customer["DistinctOrderingCustomers.total"] ))+" ")]),_c('td',[(
                      customer[
                        'DistinctOrderingCustomers.orderPaymentDateTime'
                      ]
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.formatDate( customer[ "DistinctOrderingCustomers.orderPaymentDateTime" ] ))+" ")]):_c('span',[_vm._v("-")])]),_c('td',[(customer['Customers.contactNumber'])?_c('span',[_vm._v(" "+_vm._s(customer["Customers.contactNumber"])+" ")]):_c('span',[_vm._v("-")])]),_c('td',[_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.orderFor(customer['Connections.id'])}}},[_c('v-icon',[_vm._v("mdi-cart")])],1)],1),_c('td',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.customerStats(customer['Connections.id'])}}},[_c('v-icon',[_vm._v("mdi-chart-bar")])],1)],1)])}),0)]},proxy:true}],null,true)})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }